export const siniestros = () => {
	return {
		title: 'Siniestros',
        adjuntos: 'Adjuntos del siniestro {msg}',
		loadLazyData: {
			toast: {
				error: {
					summary: 'Error',
					detail: 'Error buscando siniestros',
				},
			},
		},
		search: {
			title: 'Búsqueda de Siniestros',
			inputs: {
				numero: 'Número',
				bienAsegurado: 'Bien Asegurado',
				fechaMayorA: 'Fecha del Hecho mayor a',
				fechaMenorA: 'Fecha del Hecho menor a',
				fechaContactoDesde: 'Fecha Contacto Asegurado desde',
				fechaContactoHasta: 'Fecha Contacto Asegurado hasta',
				motivo: 'Motivo',
				estado: 'Estado',
			},
		},
		edit: {
			title: { edit: 'Editar Siniestro', new: 'Nuevo Siniestro' },
			terceroTitle: 'Nuevo Tercero',
			lesionadoTitle: 'Nuevo Lesionado',
			tercero: {
				menuModel: {
					editar: 'Editar Tercero',
					nuevo: 'Nuevo Tercero',
					borrar: 'Borrar Tercero',
				},
			},
			lesionado: {
				menuModel: {
					editar: 'Editar Lesionado',
					nuevo: 'Nuevo Lesionado',
					borrar: 'Borrar Lesionado',
				},
			},
		},
		save: {
			toast: {
				error: {
					summary: 'Error',
					detail: 'Debe seleccionar un estado',
				},
				success: {
					summary: {
						siniestro: 'Siniestro',
						actualizado: 'actualizado',
						creado: 'creado',
					},
				},
			},
			tabs: {
				datosGenerales: {
					header: 'Datos Generales',
					fieldsets: {
						principal: {
							legend: 'Principal',
							inputs: {
								tipo: 'Tipo de siniestro',
								motivo: 'Motivo',
								numero: 'Número de siniestro',
								estado: 'Estado',
								numeroReclamo: 'Número de reclamo',
								fechaRegistro: 'Fecha de registro',
								numeroOrden: 'Número de orden',
								fechaComunicacion: 'Fecha de comunicación',
								numeroExpediente: 'Número de expediente',
								fechaContacto: 'Fecha de contacto Asegurado',
								cleas: 'Cleas',
								estudioAbogado: 'Estudio de abogado',
								tipo_lesion_vida: 'Tipo de Lesión',
								diagnostico_vida: 'Diagnóstico',
								documental_vida: 'Documental',
								fecha_recepcion_denuncia:'Fecha Recep. denuncia al PAS/Broker',
								fecha_denuncia_cia:'Fecha denuncia a la compañía',
								fecha_pago:'Fecha de pago del siniestro',
							},
						},
						conductor: {
							legend: 'Conductor',
							inputs: {
								conductor: 'Conductor Asegurado',
								registro: 'Registro de conducir',
								vencimientoRegistro: 'Vencimiento registro de conducir',
								telefono: 'Teléfono',
								documento: 'Documento',
							},
						},
						intermediarios: { legend: 'Intermediarios/Responsables' },
					},
				},
				hecho: {
					header: 'Hecho',
					fieldsets: {
						datosHecho: {
							legend: 'Datos del Hecho',
							inputs: {
								provincia: 'Provincia',
								localidad: 'Localidad',
								taller: 'Taller',
								monto: 'Monto del Reclamo',
								montoFinal: 'Monto Final',
								lugarHecho: 'Lugar del Hecho',
								domicilioTaller: 'Domicilio Taller',
								montoFranquicia: 'Monto de la Franquicia',
								fechaHecho: 'Fecha del Hecho',
								horaHecho: 'Hora del Hecho',
								fechaInspeccion: 'Fecha Inspeccion',
							},
						},
						formaOcurrencia: { legend: 'Forma de Ocurrencia' },
						damageTercero: { legend: 'Daños al Tercero' },
						damageAsegurado: { legend: 'Daños al Asegurado' },
						testigos: { legend: 'Testigos' },
					},
				},
				terceros: {
					header: 'Terceros',
					fieldsets: {
						tercero: {
							legend: 'Tercero',
							inputs: {
								nombreConductor: 'Nombre Conductor',
								domicilioConductor: 'Domicilio Conductor',
								nombreTitular: 'Nombre Titular',
								domicilioTitular: 'Domicilio Titular',
								documento: 'Documento',
								cobertura: 'Cobertura',
								licenciaConducir: 'Licencia Conducir',
								expedidoPor: 'Expedido por',
								fechaNacimiento: 'Fecha de Nacimiento',
								paisNacimiento: 'País de Nacimiento',
								paisResidencia: 'País de Residencia',
								telefono: 'Teléfono',
								email: 'Email',
								poliza: 'Póliza',
								estadoCivil: 'Estado Civil',
								sexo: 'Sexo',
								company: 'Compañía',
								fieldsets: {
									legend: 'Vehículo',
									inputs: {
										year: 'Año',
										patente: 'Patente',
									},
								},
							},
						},
					},
				},
				lesionados: {
					header: 'Lesionado',
					inputs: {
						relacionAsegurado: 'Relación con Asegurado',
						tipoLesion: 'Tipo de Lesión',
						centroAsistencial: 'Centro asistencial',
						detalle: 'Detalle',
					},
				},
				observaciones: {
					header: 'Observaciones',
				},
				adjuntos: {
					header: 'Adjuntos',
				},
				seguimientos: {
					header: 'Seguimientos',
				},
			},
		},
		saveTercero: {
			toast: {
				error: {
					summary: 'Error',
					detail: 'Debe ingresar un nombre',
				},
				success: {
					summary: {
						siniestro: 'Siniestro',
						actualizado: 'actualizado',
						creado: 'creado',
					},
				},
			},
		},
		saveLesionado: {
			toast: {
				error: {
					summary: 'Error',
					detail: 'Debe ingresar un nombre',
				},
				success: {
					summary: {
						siniestro: 'Siniestro',
						actualizado: 'actualizado',
						creado: 'creado',
					},
				},
			},
		},
		components: {
			table: {
				menuModel: {
					editarSiniestro: 'Editar Siniestro',
					cargarSeguimiento: 'Cargar Seguimiento',
					verSeguimientos: 'Ver Seguimientos',
					borrarSiniestro: 'Borrar Siniestro',
				},
				columns: {
					siniestro: 'Siniestro {numero}',
					orden: 'Orden {numero}',
					fechaContacto: 'Fecha Contacto:',
					fechaHecho: 'Fecha del Hecho:',
					poliza: 'Póliza {numero}',
				},
				tooltips: {
					seguimientos: 'Tiene un seguimiento pendiente. | Tiene {count} seguimientos pendientes.',
					adjuntos: 'Tiene un adjunto. | Tiene {count} adjuntos.',
				},
				delete: {
					confirm: '¿Está seguro que desea borrar este siniestro?',
					toast: {
						success: {
							summary: 'Siniestro eliminado',
						},
						error: {
							summary: 'Error',
							detail: 'Error borrando siniestro',
						},
					},
				},
			},
		},
	};
};
