<script setup>
import { onMounted } from 'vue';
import { getDateValue } from '@helpers/dateHelper';
import { getLocale } from '../i18n/localeService';

//  https://github.com/primefaces/primevue/issues/1127

const props = defineProps({
	modelValue: null,
	showIcon: { type: Boolean, required: false, default: true },
	minDate: {
		type: Object,
		required: false,
		default: null,
	},
	showButtonBar: {
		type: Boolean,
		required: false,
		default: false,
	},
});

const emit = defineEmits(['update:modelValue']);

let reference = null;
let literal = null;

onMounted(() => {
	if (typeof props.modelValue === 'string') {
		updateValue(getDateValue(props.modelValue));
	}

	reference = new Intl.DateTimeFormat(getLocale()).formatToParts();
	literal = reference.find((el) => el.type === 'literal').value;
	reference = reference.filter((el) => el.type !== 'literal');
});

const updateValue = (date) => {
	emit('update:modelValue', date);
};

const parse = ({ target }) => {
	const numberRegex = /[^0-9]/g;
	const normalized = target.value.replace(numberRegex, '');
	const digits = [getDateDigits(reference[0]), getDateDigits(reference[1]), getDateDigits(reference[2])];

	const regex = new RegExp(`(\\d{${digits[0]}})(\\d{${digits[1]}})(\\d{${digits[2]}})`);
	const match = normalized.match(regex);

	if (!target.value) {
		emit('update:modelValue', '');
	} else if (match) {
		const day = match[getIndex('day')];
		const month = match[getIndex('month')];
		const year = match[getIndex('year')];

		const formattedDate = new Date(`${year}/${month}/${day}`);

		if (isNaN(formattedDate)) return;

		emit('update:modelValue', formattedDate);
	} else {
		const regex = new RegExp(`(\\d{0,${digits[0]}})(\\d{0,${digits[1]}})(\\d{0,${digits[2]}})`);
		target.value = normalized.replace(regex, (_, p1, p2, p3) => {
			let result = '';

			result += p1;
			if (p2) result += literal + p2;
			if (p3) result += literal + p3;

			return result;
		});
	}
};

const getIndex = (string) => {
	return reference.findIndex((el) => el.type === string) + 1;
};

const getDateDigits = (date) => {
	return Math.max(date.value.toString().length, 2);
};
</script>

<template>
	<Calendar
		:showOnFocus="false"
		:modelValue="modelValue"
		:showIcon="showIcon"
		:minDate="minDate"
		:showButtonBar="showButtonBar"
		v-bind="$attrs"
		@dateSelect="updateValue"
		@input="(evt) => parse(evt)"
		@clear-click="emit('update:modelValue', '')"
	/>
</template>
