import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
	{
		path: '/',
		name: 'dashboard',
		component: () => import('./pages/inicio/dashboard/Dashboard.vue'),
	},
	{
		path: '/eventos',
		name: 'eventos',
		component: () => import('./pages/inicio/events/Events.vue'),
	},
	{
		path: '/estadisticas',
		name: 'estadisticas',
		meta: { minimumPlan: 'professional' },
		component: () => import('./pages/inicio/stats/Stats.vue'),
	},
	{
		path: '/crm/clients',
		name: 'clients',
		component: () => import('./pages/crm/clients/Clients.vue'),
	},
	{
		path: '/crm/mailing/configuracion',
		name: 'configuracion',
        meta: { minimumPlan: 'enterprise' },
		component: () => import('./pages/crm/mailing/Configuracion.vue'),
	},
	{
		path: '/crm/mailing/send_documents',
		name: 'send_documents',
        meta: { minimumPlan: 'enterprise' },
		component: () => import('./pages/crm/mailing/SendDocuments.vue'),
	},
	{
		path: '/crm/mailing/sent_mails',
		name: 'sent_mails',
        meta: { minimumPlan: 'enterprise' },
		component: () => import('./pages/crm/mailing/MailsEnviados.vue'),
	},
	{
		path: '/crm/contacts',
		name: 'contacts',
		component: () => import('./pages/crm/contacts/Contacts.vue'),
	},
	{
		path: '/imports/import',
		name: 'import',
		component: () => import('./pages/importaciones/Importar.vue'),
	},
	{
		path: '/imports/historial',
		name: 'historialimport',
		component: () => import('./pages/importaciones/Historial.vue'),
	},
	{
		path: '/crm/groups',
		name: 'groups',
		component: () => import('./pages/crm/groups/Groups.vue'),
	},
	{
		path: '/crm/seguimientos',
		meta: { minimumPlan: 'professional' },
		name: 'seguimientos',
		component: () => import('./pages/crm/seguimientos/Seguimientos.vue'),
	},
	{
		path: '/cotizador/automotor/config',
		name: 'cotizadorconfig',
		meta: { minimumPlan: 'professional' },
		component: () => import('./pages/cotizador/automotor/config/Config.vue'),
	},
	{
		path: '/cotizador/automotor/cotizar',
		name: 'cotizar',
		meta: { minimumPlan: 'professional' },
		props: true,
		component: () => import('./pages/cotizador/automotor/Cotizador.vue'),
	},
	{
		path: '/cotizador/automotor/historial/historial',
		meta: { minimumPlan: 'professional' },
		name: 'cotizadorhistorial',
		component: () => import('./pages/cotizador/automotor/historial/Historial.vue'),
	},
	{
		path: '/cotizador/automotor/emisiones/emisiones',
		meta: { minimumPlan: 'professional' },
		name: 'cotizadoremisiones',
		component: () => import('./pages/cotizador/automotor/emisiones/Emisiones.vue'),
	},
	{
		path: '/cotizador/viajero/cotizar',
		meta: { minimumPlan: 'professional' },
		name: 'cotizarviajero',
		component: () => import('./pages/cotizador/viajero/Cotizar.vue'),
	},
	{
		path: '/cotizador/prospectos-art',
		name: 'prospectos',
		component: () => import('./pages/cotizador/prospectos/Prospectos.vue'),
	},
	{
		path: '/rubrica/operaciones',
		name: 'operaciones',
		component: () => import('./pages/rubrica/Operaciones.vue'),
	},
	{
		path: '/rubrica/historial',
		name: 'historial',
		component: () => import('./pages/rubrica/Historial.vue'),
	},
	{
		path: '/administrar/usuariospropios/roles',
		meta: { minimumPlan: 'enterprise' },
		name: 'roles',
		component: () => import('./pages/administrar/usuariospropios/permisos/Roles.vue'),
	},
    {
		path: '/administrar/usuariospropios/permisos',
		meta: { minimumPlan: 'enterprise' },
		name: 'permisos',
		component: () => import('./pages/administrar/usuariospropios/permisos/Permisos.vue'),
	},
	{
		path: '/administrar/auditoria',
		name: 'auditoria',
		component: () => import('./pages/administrar/auditoria/Auditoria.vue'),
	},
	{
		path: '/administrar/usuariospropios/misdatos',
		name: 'misdatos',
		component: () => import('./pages/administrar/usuariospropios/MisDatos.vue'),
	},
	{
		path: '/administrar/usuariospropios/productores',
		name: 'productores',
		component: () => import('./pages/administrar/usuariospropios/productores/MisProductores.vue'),
	},
	{
		path: '/administrar/organizacion/productores',
		meta: { minimumPlan: 'enterprise' },
		name: 'productoresorga',
		component: () => import('./pages/administrar/organizacion/ProductoresTodos.vue'),
	},
	{
		path: '/administrar/organizacion/estructura',
		meta: { minimumPlan: 'enterprise' },
		name: 'estructuraorga',
		component: () => import('./pages/administrar/organizacion/Estructura.vue'),
	},
	{
		path: '/administrar/usuariospropios/empleados',
		name: 'empleados',
		component: () => import('./pages/administrar/usuariospropios/empleados/Empleados.vue'),
	},
	{
		path: '/administrar/usuariospropios/dateros',
		name: 'dateros',
		component: () => import('./pages/administrar/usuariospropios/empleados/Dateros.vue'),
	},
	{
		path: '/tesoreria/pagos/pendientes',
		name: 'pagos_pendientes',
		component: () => import('./pages/tesoreria/pagos/Pendientes.vue'),
	},
	{
		path: '/tesoreria/pagos/rendiciones',
		name: 'rendiciones',
		component: () => import('./pages/tesoreria/pagos/Rendiciones.vue'),
	},
	{
		path: '/tesoreria/pagos/realizados',
		name: 'realizados',
		component: () => import('./pages/tesoreria/pagos/Realizados.vue'),
	},
	{
		path: '/tesoreria/pagos/pendientes-clientes',
		name: 'pendientes_cliente',
		component: () => import('./pages/tesoreria/pagos_cliente/PendientesCliente.vue'),
	},
	{
		path: '/tesoreria/pagos/cobrados-clientes',
		name: 'cobrados_cliente',
		component: () => import('./pages/tesoreria/pagos_cliente/CobradosCliente.vue'),
	},
	{
		path: '/tesoreria/comisiones/pendientes',
		name: 'comisiones_pendientes',
		component: () => import('./pages/tesoreria/comisiones/Pendientes.vue'),
	},
	{
		path: '/tesoreria/comisiones/preliquidaciones',
		name: 'preliquidaciones',
		component: () => import('./pages/tesoreria/comisiones/Preliquidaciones.vue'),
	},
	{
		path: '/tesoreria/cheques',
		name: 'tesoreria_cheques',
		component: () => import('./pages/tesoreria/Cheques.vue'),
	},
	{
		path: '/tesoreria/movimientos-caja',
		name: 'movimientos_caja',
		component: () => import('./pages/tesoreria/MovimientosCaja/MovimientosCaja.vue'),
	},
	{
		path: '/siniestros',
		name: 'siniestros',
		component: () => import('./pages/siniestros/Siniestros.vue'),
	},
	{
		path: '/polizas',
		name: 'polizas',
		component: () => import('./pages/polizas/SnapshotList.vue'),
	},
	{
		path: '/reportes/unidades',
		name: 'reportes_unidades',
		component: () => import('./pages/reportes/unidades/Unidades.vue'),
	},
	{
		path: '/reportes/renovaciones',
		name: 'renovaciones',
		component: () => import('./pages/reportes/renovaciones/Renovaciones.vue'),
	},
	{
		path: '/reportes/refacturaciones',
		name: 'refacturaciones',
		component: () => import('./pages/reportes/refacturaciones/Refacturaciones.vue'),
	},
	{
		path: '/reportes/emisiones/pendientes',
		name: 'emisiones_pendientes',
		component: () => import('./pages/reportes/emisiones/Pendientes.vue'),
	},
	{
		path: '/reportes/emisiones/realizadas',
		name: 'realizadas',
		component: () => import('./pages/reportes/emisiones/Realizadas.vue'),
	},
	{
		path: '/reportes/vigentes',
		name: 'vigentes',
		component: () => import('./pages/reportes/vigentes/Vigentes.vue'),
	},
	{
		path: '/reportes/descargados',
		name: 'reportes_descargados',
		component: () => import('./pages/reportes/reportes_descargados/ReportesDescargados.vue'),
	},
	{
		path: '/administrar/coberturas',
		name: 'coberturas',
		component: () => import('./pages/administrar/coberturas/Coberturas.vue'),
	},
	{
		path: '/administrar/suscripcion',
		name: 'suscripcion',
		component: () => import('./pages/administrar/suscripcion/Suscripcion.vue'),
	},
	{
		path: '/administrar/companies',
		name: 'companies',
		component: () => import('./pages/administrar/companies/Companies.vue'),
	},
	{
		path: '/videos',
		name: 'videos',
		component: () => import('./pages/VideosContainer.vue'),
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
