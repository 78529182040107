import { formEnabled } from '@service/FormService';
import { ref } from 'vue'
import Axios from 'axios';
import { lateralToast } from '@service/MessageService';
import { tizer } from '@service/i18nService';

export function searchDefault(queryString, resourceName, filters) {
	let filter = Object.values(filters)[0];
	filter.value = queryString;

	const params = {
		rows: 25,
		first: 0,
		filters: filters,
	};

	return useGetAll(params, resourceName);
}

export function transformParamsFilters(params) {
	let filters = [];
	if (params.filters) {
		for (const [key, value] of Object.entries(params.filters)) {
			// typeof vcopy.value == 'object' para evitar el problema del AutoComplete de primevue
			// que no permite v-model ear a una propiedad del objeto (el id)
			// y bindea a todo el result del combo
			if (Array.isArray(value.value) && !value.value.length) value.value = undefined;
			let vcopy = JSON.parse(JSON.stringify(value));
			if (vcopy.value || vcopy.value === false) {
				if (params.converts) {
					for (const converter of params.converts) {
						converter(vcopy);
					}
					if (vcopy.value === null) continue;
				}
				if (Array.isArray(vcopy.value) && (vcopy.operator == 'in' || vcopy.operator == 'notin')) {
					vcopy.value = vcopy.value.map((arr_val) => arr_val.id);
					if (!vcopy.value.length) {
						value.applied = false;
						continue;
					}
				} else if (Array.isArray(vcopy.value) && vcopy.operator == 'eq') {
					// Caso de arrays de enums (como estado poliza)
					if (!vcopy.value.length) {
						value.applied = false;
						vcopy.value = null;
						continue;
					}
				} else if (Array.isArray(vcopy)) {
					// encoding of arrays que no son objetos relacionados
					const arrStr = encodeURIComponent(JSON.stringify(vcopy));
					filters[key] = arrStr;
				} else if (vcopy && vcopy.value.id) {
					if (typeof vcopy == 'object') vcopy.value = vcopy.value.id;

					filters[key] = vcopy;
				} else {
					//console.log("value normal", vcopy.value)
					//vcopy.value = vcopy.value.replace('&', '')
				}

				value.applied = true;
				filters.push(vcopy);
			}
			if (!vcopy.value && vcopy.value !== false) value.applied = false;
		}
	}
	return filters;
}

export function useGetAll(params, resourceName) {
	// adapto al formato que pide el backend
	let queryParams = {
		page: (params.page ?? 0) + 1, // viene 0-indexed
		filter: [],
	};

	queryParams.filter = JSON.stringify(transformParamsFilters(params));

	if (params.rows) queryParams.limit = params.rows;
	if (params.first) queryParams.start = params.first;
	if (params.sortField) queryParams.sort = JSON.stringify(params.sortField);
	if (params.accumulate) queryParams.accumulate = params.accumulate;
	if (params.summarize) queryParams.summarize = params.summarize;

	const queryString = Object.keys(queryParams)
		.map((key) => key + '=' + queryParams[key])
		.join('&');
	return Axios.get(`${resourceName}?${queryString}`);
}

export function useGet(resourceName) {
	return Axios.get(`${resourceName}`);
}

export function usePost(resourceName, data) {
	return Axios.post(resourceName, data);
}

export function logSlackError(error) {
	return usePost(import.meta.env.VITE_BASE_URL + 'errors', { message: error });
}

export function usePut(resourceName, data) {
	return Axios.put(resourceName, data);
}

export function useDelete(resourceName, data) {
	return Axios.delete(resourceName, data);
}

export async function useDeleteWithErrors(resourceName, data){
    try {
        const response = await Axios.delete(resourceName, data)
        return true
    } catch(error) {
        const errors = handleValidationErrors(error)
        lateralToast.value = { severity: 'error', summary: errors.join(' - '), life: 4000 };
        return false
    }        
    
}

// recibe la ref de la entidad y la copy ya procesada con el simpleassociations o lo que necesite
export const saveGenericEntity = async (selectedEntity, entityCopy, resourceName, resultKey) => {
	formEnabled.value = false;
	let resp = {};
	if (selectedEntity.value.id) {
		try {
			const response = await usePut(`${resourceName}/${selectedEntity.value.id}`, entityCopy);
			selectedEntity.value = response.data[resultKey];
			resp = { status: 'success', type: 'put' };
		} catch (error) {
			resp = { status: 'error', errors: handleValidationErrors(error) };
		}
	} else {
		try {
			const response = await usePost(resourceName, entityCopy);
			selectedEntity.value = response.data[resultKey];
			resp = { status: 'success', type: 'post' };
		} catch (error) {
			resp = { status: 'error', errors: handleValidationErrors(error) };
		}
	}
	formEnabled.value = true;
	return resp;
};

// Este ya maneja los alerts de success y error y devuelve true/false. Necesita 2 params nuevos.
export const betterSaveGenericEntity = async (selectedEntity, entityCopy, resourceName, resultKey, gender, entityLabel) => {
    const response = await saveGenericEntity(selectedEntity, entityCopy, resourceName, resultKey)
    if (response.errors) {
        lateralToast.value = { severity: response.status, summary: response.errors.join(' - '), life: 4000 };
        return false
    } else {
        lateralToast.value = {
            severity: response.status,
            summary: response.type === 'put' ? tizer(`shared.messages.entityupdated${gender}`, { msg: entityLabel }) : tizer(`shared.messages.entitycreated${gender}`, { msg: entityLabel }),
            life: 4000,
        };
        return true
    }
}

export const handleValidationErrors = (error) => {
	let errors = [];
	try {
		if (error.response && error.response.data) {
			for (const [key, value] of Object.entries(error.response.data.errors)) {
				//errors.push(`${key} ${value}`);
                errors.push(value); // dejo solo los values, ya no hace falta mostrar las keys
			}
		} else {
			errors.push('Error de procesamiento');
		}
	} catch (error) {
		errors.push('Error de procesamiento');
	}
	return errors;
};

export const apiResults = ref({})

export const getApiResults = (key) => {
    if (!apiResults.value[key]) {
        apiResults.value[key] = {
            total:0,
            loading: false,
            data: []
        }
    }
    return apiResults.value[key]
}

export const useLoadData = async(params, endpoint, key) => {
    let apiResult = getApiResults(key)
    apiResult.loading = true
    //console.log(params)
    const response = await useGetAll(params, endpoint)
    //console.log(response)
    if (response.status == 200){
        apiResult.total = response.data.meta.total
        apiResult.data = response.data[key]
    }

    apiResult.loading = false
}