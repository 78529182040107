export const labels = () => {
	return {
        has_access: 'Tiene acceso al sistema',
        cant_access: 'Sin acceso al sistema',
		orden: 'Orden',
        agreement: 'Acuerdo',
        agreements: 'Acuerdos',
        codigo: 'Código',
        pas: 'PAS',
		seguimiento: 'Seguimiento',
        seccion: 'Sección',
		sindatos: 'Sin datos',
		alicuota: 'Alicuota',
		buscar: 'Buscar',
		editar: 'Editar',
		eliminar: 'Eliminar',
		user: 'Usuario',
		users: 'Usuarios',
		client: 'Cliente',
		accion: 'Acción',
		acciones: 'Acciones',
		listado: 'Listado',
		domicilio: 'Domicilio',
		adjuntos: 'Adjuntos',
		entity_type: 'Tipo de Entidad',
		entity: 'Entidad',
		company: 'Compañía',
		producerCode: 'Código de Productor',
		name: 'Nombre',
		nombre: 'Nombre',
        apellido: 'Apellido',
		employee: 'Empleado',
		employees: 'Empleados',
		tipoPersona: 'Tipo de Persona',
		matricula: 'Matrícula',
		telefono: 'Teléfono',
		telefonos: 'Teléfonos',
		denominacion: 'Denominación',
		intermediario: 'Intermediario',
		codigoOrganizacion: 'Código Organización',
		organizacion: 'Organización',
		organizador: 'Organizador',
		perteneceGrupo: 'Pertenece al Grupo',
		personalizada: 'Personalizada',
		predeterminada_company: 'Predeterminada en Compañía',
		asegurado: 'Asegurado',
		endoso: 'Endoso',
		endosos: 'Endosos',
		poliza: 'Póliza',
		proposal: 'Propuesta',
		productor: 'Productor',
		productorExterno: 'Productor Externo',
		producers: 'Productores',
		prospecto: 'Prospecto',
		code: 'Código',
		codes: 'Códigos',
		cobertura: 'Cobertura',
		coberturas: 'Coberturas',
		module: 'Módulo',
        modules: 'Módulos',
		productorCodigo: 'Productor / Código',
		ramo: 'Ramo',
		ramos: 'Ramos',
		siniestro: 'Siniestro',
		vehiculo: 'Vehículo',
		filtrosAplicados: 'Filtros Aplicados',
		mail: 'Mail',
		cargo: 'Cargo',
		numero: 'Número',
		banco: 'Banco',
		recibo: 'Recibo',
		direccionPredefinida: 'Dirección predefinida',
		tarjetaPredefinida: 'Tarjeta predefinida',
		marcarPredefinida: 'Marcar como predefinida',
		predefinido: ' predefinido',
		marcarPredefinido: 'Marcar predefinido',
		tipoDireccion: 'Tipo Dirección',
		direccion: 'Dirección',
		provincia: 'Provincia',
		localidad: 'Localidad',
		codigoPostal: 'Código Postal',
		tarjeta: 'Tarjeta',
		titular: 'Titular',
		fechaVencimiento: 'Fecha de Vencimiento',
		date: 'Fecha',
        recotizar: 'Recotizar',
		cotization: 'Cotización',
		best_cotization: 'Mi mejor cotización',
		cotizationDateGte: 'Fecha cotización mayor a',
		cotizationDateLte: 'Fecha cotización menor a',
		razon_social: 'Razón social',
		or: 'or',
		state: 'Estado',
		generatingFile: 'Generando archivo...',
		yes: 'Si',
		no: 'No',
		tipoSolicitud: 'Tipo de Solicitud',
		emision: 'Emisión',
		principal: 'Principal',
		datos_complementarios: 'Datos complementarios',
		datos_consolidados: 'Datos consolidados',
		bienes: 'Bienes',
		bienes_consolidados: 'Bienes consolidados',
		fechas: 'Fechas',
		costos: 'Costos',
		importe: 'Importe',
		porcentaje: 'Porcentaje',
		formaPago: 'Forma de Pago',
		saldo: 'Saldo',
		currency: 'Moneda',
		concepto: 'Concepto',
		accesorios: 'Accesorios',
		automotores: 'Automotores',
		ubicacion: 'Ubicación',
		cuotas: 'Cuotas',
		cuota: 'Cuota',
		detalle: 'Detalle',
		tasa_x_mil: 'Tasa x mil',
		suma_asegurada: 'Suma asegurada',
		fecha_desde: 'Fecha desde',
		fecha_hasta: 'Fecha hasta',
		fecha_desde_auditoria: 'Fecha de Auditoría mayor a',
		fecha_hasta_auditoria: 'Fecha de Auditoría menor a',
		valor_fijo: 'Valor Fijo',
		origen: 'Origen',
		email: 'Email',
		oficina: 'Oficina',
		fechaNacimiento: 'Fecha de Nacimiento',
		documentos: 'Documentos',
		desde: 'Desde',
		hasta: 'Hasta',
		estado: 'Estado',
		remitente: 'Remitente',
		destinatario: 'Destinatario',
		tipo: 'Tipo',
		asunto: 'Asunto',
        rol: 'Rol',
		observaciones: 'Observaciones',
		periodo_emision_desde: 'Período Emisión Desde',
		periodo_emision_hasta: 'Período Emisión Hasta',
		inicio_vigencia_desde: 'Inicio Vigencia Desde',
		inicio_vigencia_hasta: 'Inicio Vigencia Hasta',
		documento_ya_enviado: 'Documento ya enviado',
		buscar_numero_productor: 'Buscar por número de Productor',
		buscar_polizas: 'Buscar en Pólizas',
		incluir_polizas: 'Incluir Pólizas de los Ramos',
		poliza_propuesta: 'Póliza/Propuesta',
		poliza_original: 'Póliza original (endoso 0)',
		poliza_original: 'Póliza original (endoso 0)',
		original_policy_id: 'ID de Póliza',
		bien_asegurado: 'Bien Asegurado',
		not_yet: 'Aún no',
		todas_polizas: 'Todas las pólizas',
		coaseguros: 'Coaseguros',
		coaseguro: 'Coaseguro',
		mail_cliente: 'Al mail de cada cliente',
		todos_mismo_mail: 'Todos a un mismo mail',
		pesos_argentinos: 'Pesos argentinos',
		solicitud: 'Solicitud',
        pais: 'País',
		telefonica: 'Telefónica',
		presencial: 'Presencial',
		participante: 'Participante',
		responsable: 'Responsable',
		responsable_usuario: 'Responsable (usuario)',
		responsable_productor_externo: 'Responsable (productor externo)',
		usuario_responsable_participante: 'Usuario (responsable o participante)',
		prioridad: 'Prioridad',
		titulo: 'Título',
		clasificacion: 'Clasificación',
		clausula_ajuste: 'Clausula Ajuste',
		fecha_recordatorio_mayor: 'Fecha de Recordatorio mayor a',
		fecha_recordatorio_menor: 'Fecha de Recordatorio menor a',
		id: 'ID',
		id_cliente: 'ID de Cliente',
		id_seguimiento: 'ID de Seguimiento',
		fecha_vencimiento: 'Fecha de vencimiento',
		description: 'Descripción',
		credit_card: 'Tarjeta de crédito',
        bank_account: 'Cuenta / CBU',
		detalle: 'Detalle',
		tasa_x_mil: 'Tasa x mil',
		liquidado: 'Liquidado el',
		sin_liquidar: 'Sin Liquidar',
		tipo_documento: 'Tipo Documento',
		numero_documento: 'Número de Documento',
		confirm: 'Confirmar',
        referencia: 'Referencia',
		videotutoriales: 'Videotutoriales',
		descargar_propuesta: 'Descargar Propuesta',
		//agregado para polizas
		descargar_certificado: 'Descargar Certificado',
		copiar_poliza: 'Copiar Póliza',
		renovar_poliza: 'Renovar Póliza',
		asegurados: 'Asegurados',
		fecha_solicitud: 'Fecha solicitud',
		vigencia_desde: 'Vigencia desde',
		vigencia_hasta: 'Vigencia hasta',
		codigo_productor: 'Código productor',
		fecha_envio_cliente: 'Fecha envío al cliente',
		fecha_recepcion: 'Fecha Recepción',
		forma_despacho: 'Forma despacho',
		despacho: 'Despacho',
		tipo_operacion: 'Tipo operación',
		vigencia_desde_mayor: 'Vigencia desde mayor a',
		vigencia_desde_menor: 'Vigencia desde menor a',
		vigencia_hasta_mayor: 'Vigencia hasta mayor a',
		vigencia_hasta_menor: 'Vigencia hasta menor a',
		fecha_solicitud_desde: 'Fecha de solicitud desde',
		fecha_solicitud_hasta: 'Fecha de solicitud hasta',
		fecha_envio_cliente_desde: 'Fecha envío la Cliente desde',
		fecha_envio_cliente_hasta: 'Fecha envío la Cliente hasta',
		fecha_recepcion_desde: 'Fecha recepción desde',
		fecha_recepcion_hasta: 'Fecha recepción hasta',
        frozen_date_desde: 'Fecha bloqueo desde',
		frozen_date_hasta: 'Fecha bloqueo hasta',
		condicion_iva: 'Condición frente al IVA',
		fecha_emision: 'Fecha Emisión',
		general: 'General',
		vigencia: 'Vigencia',
        franquicia: 'Franquicia',
        admin: 'Administrador',
        inactivo: 'Inactivo',
        activo: 'Activo',
		numero_asegurado: 'Número / Asegurado',
		fecha_emision_desde: 'Fecha emisión desde',
		fecha_emision_hasta: 'Fecha emisión hasta',
		sin_fecha_despacho: 'Sin fecha de despacho',
		ver_poliza: 'Ver / Editar Póliza',
		ver_polizas_vigentes: 'Ver pólizas vigentes',
		ver_asegurado: 'Ver Asegurado',
		ver_comentarios: 'Comentarios / Actividad',
		error: 'Error',
		agregar: 'Agregar',
		descargar: 'Descargar',
		cbu: 'CBU',
	};
};
