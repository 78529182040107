<script setup>
import { messages, deleteMessage, generatingFile, notaVisible, currentNota } from '@service/MessageService';
import { ref, computed, onBeforeUpdate, watch, provide } from 'vue';
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import Unauthorized from '@pages/Unauthorized.vue';
import { usePrimeVue } from 'primevue/config';
import { useRoute } from 'vue-router';
import { useAuthStore } from '@stores/AuthStore';
import { pageLoading } from './main';
import ProgressSpinner from 'primevue/progressspinner';
import { getLocale } from './i18n/localeService';

const primevue = usePrimeVue();
const route = useRoute();
const auth = useAuthStore();
const layoutMode = ref('static');
const staticMenuInactive = ref(false);
const overlayMenuActive = ref(false);
const mobileMenuActive = ref(false);
const menuActive = ref(false);

const menuClick = ref();

const menu = computed(() => {
    let menuitems = []
	menuitems.push(
		{
			label: 'Gestión',
			items: [
				{
					label: 'Inicio',
					icon: 'pi pi-fw pi-home',
					to: '/',
				},
				{
					label: 'Eventos',
					icon: 'pi pi-fw pi-calendar',
					to: '/eventos',
				},
				{
					label: 'Estadísticas (BETA)',
					icon: 'pi pi-fw pi-chart-bar',
					to: '/estadisticas',
				},
			],
		},
		{
			label: 'CRM',
			icon: 'pi pi-fw pi-users',
			items: [
				{ label: 'Clientes / Prospectos', icon: 'pi pi-fw pi-user', to: '/crm/clients' },
				{ label: 'Seguimientos / Tickets', icon: 'la la-comments text-2xl', to: '/crm/seguimientos' },
				{ label: 'Contactos', icon: 'pi pi-fw pi-book', to: '/crm/contacts' },
				{ label: 'Grupos', icon: 'pi pi-fw pi-users', to: '/crm/groups' },
                ...( (auth.hasModuleAvailable('crm/mailing'))?
                [
                    {
                        label: 'Mailing',
                        icon: 'pi pi-fw pi-envelope',
                        items: [
                            { label: 'Configuración', icon: 'pi pi-fw pi-cog', to: { name: 'configuracion' } },
                            { label: 'Enviar Documentos', icon: 'pi pi-fw pi-send', to: { name: 'send_documents' } },
                            { label: 'Mails Enviados', icon: 'pi pi-fw pi-envelope', to: { name: 'sent_mails' } },
                        ],
                    }
                ]
                : []),
				/*{
					label: 'Mailing',
					icon: 'pi pi-fw pi-envelope',
					items: [
						{ label: 'Configuración', icon: 'pi pi-fw pi-cog', to: { name: 'configuracion' } },
						{ label: 'Enviar Documentos', icon: 'pi pi-fw pi-send', to: { name: 'send_documents' } },
						{ label: 'Mails Enviados', icon: 'pi pi-fw pi-envelope', to: { name: 'sent_mails' } },
					],
				},*/
			],
		},
		{
			label: 'Pólizas',
			to: { name: 'polizas' },
		},
		{
			label: 'Cotizador',
			icon: 'pi pi-fw pi-tags',
			items: [
				{
					label: 'Automotor',
					icon: 'pi pi-fw pi-car',
					items: [
						{ label: 'Configurar', icon: 'pi pi-fw pi-sitemap', to: '/cotizador/automotor/config' },
						{ label: 'Cotizar', icon: 'pi pi-fw pi-tags', to: '/cotizador/automotor/cotizar' },
						{
							label: 'Historial de cotizaciones',
							icon: 'pi pi-fw pi-replay',
							to: '/cotizador/automotor/historial/historial',
						},
						{
							label: 'Emisiones realizadas',
							icon: 'pi pi-fw pi-send',
							to: '/cotizador/automotor/emisiones/emisiones',
						},
					],
				},
				/*{
					label: 'Asistencia al Viajero',
					icon: 'la la-plane-departure text-2xl',
					items: [{ label: 'Cotizar', icon: 'pi pi-fw pi-tags', to: '/cotizador/viajero/cotizar' }],
				},*/
				{ label: 'Prospectos ART', icon: 'la la-hard-hat text-2xl', to: { name: 'prospectos' } },
			],
		},
		{
			label: 'Interfaces con Compañías',
			icon: 'pi pi-fw pi-cloud-download',
			items: [
				{ label: 'Importar', icon: 'pi pi-fw pi-cloud-download', to: '/imports/import' },
				{ label: 'Historial de importaciones', icon: 'pi pi-fw pi-history', to: '/imports/historial' },
			],
		},
		{
			label: 'Siniestros',
			to: { name: 'siniestros' },
		},
		{
			label: 'Reportes',
			icon: 'pi pi-fw pi-list',
			items: [
				{ label: 'Renovaciones', icon: 'pi pi-fw pi-copy text-xl', to: { name: 'renovaciones' } },
				{
					label: 'Refacturaciones',
					icon: 'la la-file-invoice-dollar text-2xl',
					to: { name: 'refacturaciones' },
				},
				{
					label: 'Emisiones',
					icon: 'la la-file-alt text-2xl',
					items: [
						{
							label: 'Pendientes',
							icon: 'la la-edit text-2xl',
							to: { name: 'emisiones_pendientes' },
						},
						{
							label: 'Realizadas',
							icon: 'la la-file-alt text-2xl',
							to: { name: 'realizadas' },
						},
					],
				},
				{ label: 'Vigentes Históricas', icon: 'pi pi-fw pi-history', to: { name: 'vigentes' } },
				{ label: 'Unidades', icon: 'pi pi-fw pi-car', to: { name: 'reportes_unidades' } },
				{
					label: 'Reportes Descargados',
					icon: 'la la-archive text-2xl',
					to: { name: 'reportes_descargados' },
				},
			],
		},
		{
			label: 'Tesorería',
			icon: 'pi pi-fw pi-dollar',
			items: [
				{
					label: 'Pagos en Compañía',
					icon: 'pi pi-credit-card',
					items: [
						{ label: 'Pendientes', icon: 'pi pi-list', to: { name: 'pagos_pendientes' } },
						{ label: 'Rendiciones', icon: 'pi pi-money-bill', to: { name: 'rendiciones' } },
						{ label: 'Realizados', icon: 'pi pi-credit-card', to: { name: 'realizados' } },
					],
				},
				{
					label: 'Pagos de Cliente',
					icon: 'pi pi-dollar',
					items: [
						{ label: 'Pendientes', icon: 'pi pi-list', to: { name: 'pendientes_cliente' } },
						{ label: 'Cobrados', icon: 'pi pi-money-bill', to: { name: 'cobrados_cliente' } },
					],
				},
				{
					label: 'Comisiones',
					icon: 'pi pi-fw pi-briefcase',
					items: [
						{ label: 'Pendientes', icon: 'las la-calculator la-lg', to: { name: 'comisiones_pendientes' } },
						{ label: 'Preliquidaciones', icon: 'pi pi-list', to: { name: 'preliquidaciones' } },
					],
				},
				{ label: 'Cheques', icon: 'pi pi-fw pi-money-bill', to: '/tesoreria/cheques' },
				{ label: 'Movimientos Caja', icon: 'la la-exchange-alt text-xl', to: { name: 'movimientos_caja' } },
			],
		},
        ...( (getLocale() == 'es-ar')?
            [
                {
                    label: 'Rúbrica',
                    icon: 'pi pi-fw pi-file',
                    items: [
                        { label: 'Operaciones', icon: 'pi pi-fw pi-book', to: '/rubrica/operaciones' },
                        { label: 'Historial', icon: 'pi pi-fw pi-history', to: '/rubrica/historial' },
                    ],
                },
            ]
            : []),
		{
			label: 'Administrar',
			icon: 'pi pi-fw pi-cog',
			items: [
				{
					label: 'Usuarios Propios',
					icon: 'pi pi-fw pi-user',
					items: [
						{
							label: 'Mis datos',
							icon: 'pi pi-fw pi-user-edit',
							to: '/administrar/usuariospropios/misdatos',
						},
						{
							label: 'Productores',
							icon: 'la la-user-tie text-2xl',
							to: '/administrar/usuariospropios/productores',
						},
						{
							label: 'Empleados / Dateros',
							icon: 'la la-user-friends text-2xl',
							items: [
								{
									label: 'Empleados',
									icon: 'la la-user-check text-2xl',
									to: '/administrar/usuariospropios/empleados',
								},
								{
									label: 'Dateros (sin acceso)',
									icon: 'la la-user-tag text-2xl',
									to: '/administrar/usuariospropios/dateros',
								},
							],
						},
                        {
							label: 'Permisos', 
                            icon: 'pi pi-fw pi-unlock',
							items: [
								{
									label: 'De Usuarios',
									icon: 'la la-user-cog text-2xl',
									to: '/administrar/usuariospropios/permisos',
								},
								{
									label: 'Definir Roles',
									icon: 'la la-user-tag text-2xl',
									to: '/administrar/usuariospropios/roles',
								},
							],
						},
						//{ label: 'Permisos', icon: 'pi pi-fw pi-unlock', to: '/administrar/usuariospropios/permisos' },
					],
				},
				{
					label: 'Suscripción',
					icon: 'pi pi-fw pi-credit-card',
					to: '/administrar/suscripcion',
				},
				{
					label: 'Soy Organizador',
					icon: 'la la-sitemap text-2xl',
					items: [
						{
							label: 'Mi Estructura',
							icon: 'la la-sitemap text-2xl',
							to: '/administrar/organizacion/estructura',
						},
						{
							label: 'Productores (todos)',
							icon: 'pi pi-fw pi-user-edit',
							to: '/administrar/organizacion/productores',
						},
					],
				},
				{
					label: 'Compañías',
					icon: 'pi pi-fw pi-building',
					to: '/administrar/companies',
				},
				{ label: 'Auditoria', icon: 'pi pi-fw pi-desktop', to: '/administrar/auditoria' },
				{ label: 'Coberturas', icon: 'pi pi-shield', to: '/administrar/coberturas' },
			],
		},
		{
			label: 'Videotutoriales',
			to: { name: 'videos' },
		},
    );

    return menuitems
});

const forceReRender = () => {
	auth.handle401Error();
};

provide('forceReRender', forceReRender);

function onWrapperClick() {
	if (!menuClick.value) {
		overlayMenuActive.value = false;
		mobileMenuActive.value = false;
	}

	menuClick.value = false;
}

function onMenuToggle() {
	menuClick.value = true;

	if (isDesktop()) {
		if (layoutMode.value === 'overlay') {
			if (mobileMenuActive.value === true) {
				overlayMenuActive.value = true;
			}

			overlayMenuActive.value = !overlayMenuActive.value;
			mobileMenuActive.value = false;
		} else if (layoutMode.value === 'static') {
			staticMenuInactive.value = !staticMenuInactive.value;
		}
	} else {
		mobileMenuActive.value = !mobileMenuActive.value;
	}

	event.preventDefault();
}

function onSidebarClick() {
	menuClick.value = true;
}

function onMenuItemClick(event) {
	if (event.item && !event.item.items) {
		overlayMenuActive.value = false;
		mobileMenuActive.value = false;
	}
}

function addClass(element, className) {
	if (element.classList) element.classList.add(className);
	else element.className += ' ' + className;
}

function removeClass(element, className) {
	if (element.classList) element.classList.remove(className);
	else
		element.className = element.className.replace(
			new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
			' '
		);
}

function isDesktop() {
	return window.innerWidth >= 992;
}

function isSidebarVisible() {
	if (isDesktop()) {
		if (layoutMode.value === 'static') return !staticMenuInactive.value;
		else if (layoutMode.value === 'overlay') return overlayMenuActive.value;
	}

	return true;
}

const containerClass = computed(() => {
	return [
		'layout-wrapper',
		{
			'layout-overlay': layoutMode.value === 'overlay',
			'layout-static': layoutMode.value === 'static',
			'layout-static-sidebar-inactive': staticMenuInactive.value && layoutMode.value === 'static',
			'layout-overlay-sidebar-active': overlayMenuActive.value && layoutMode.value === 'overlay',
			'layout-mobile-sidebar-active': mobileMenuActive.value,
			'p-input-filled': primevue.config.inputStyle === 'filled',
			'p-ripple-disabled': primevue.config.ripple === false,
			//'layout-theme-light': this.$appState.theme.startsWith('saga'),
		},
	];
});

const logo = computed(() => {
	return '/images/new_logo_128.png';
});

watch(
	() => route.name,
	() => (menuActive.value = false)
);

onBeforeUpdate(() => {
	if (mobileMenuActive.value) addClass(document.body, 'body-overflow-hidden');
	else removeClass(document.body, 'body-overflow-hidden');
});
</script>

<template>
	<ScrollTop />
	<Toast position="top-center" group="multitoast">
		<template #message="slotProps">
			<div class="flex flex-column align-items-center" style="flex: 1">
				<div class="text-center">
					<i
						:class="slotProps.message.severity == 'error' ? 'pi pi-times' : 'pi pi-check'"
						style="font-size: 2rem"
					/>
					<div class="font-bold text-l text-left my-3">
						<p class="ml-1" v-for="deta in slotProps.message.detail" :key="deta">
							<span v-html="deta"></span>
						</p>
					</div>
				</div>
			</div>
		</template>
	</Toast>
	<ConfirmDialog />
	<ConfirmDialog group="multiline">
		<template #message="slotProps">
			<div class="flex flex-column align-items-center" style="flex: 1">
				<div class="text-center">
					<i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
					<div class="text-l text-left my-3">
						<p v-html="line" v-for="line in slotProps.message.lines" :key="line" class="ml-1"></p>
					</div>
				</div>
			</div>
		</template>
	</ConfirmDialog>
	<div v-if="auth.isAuthenticated" :class="containerClass" @click="onWrapperClick">
		<AppTopBar @menu-toggle="onMenuToggle" />
		<div class="layout-sidebar" @click="onSidebarClick">
			<AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
		</div>
		<div class="layout-main-container">
			<transition-group name="p-message" tag="div">
				<Message
					v-for="msg of messages"
					:key="msg.id"
					:severity="msg.severity"
					@close="deleteMessage(msg.id)"
				>
					<div class="line-height-2" v-html="msg.content" />
				</Message>
			</transition-group>

			<Dialog
				:header="currentNota.title"
				v-model:visible="notaVisible"
				:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
				:style="{ width: '50vw' }"
			>
				<Editor readonly v-model="currentNota.nota" editorStyle="height: 320px">
					<template v-slot:toolbar>
						<span class="ql-formats" />
					</template>
				</Editor>
			</Dialog>

			<ProgressSpinner
				class="absolute top-50 left-50 z-5"
				style="transform: translate(-50%, -50%)"
				v-if="pageLoading"
			/>
			<div class="layout-main">
				<BlockUI :blocked="pageLoading" fullScreen>
					<router-view>
						<template #default="{ Component }">
							<ProgressBar
								mode="indeterminate"
								class="relative top-50 left-50 z-5 w-full mt-2"
								style="transform: translate(-50%, -50%)"
								v-if="generatingFile"
							/>
							<component :is="Component" />
						</template>
					</router-view>
				</BlockUI>
			</div>

			<AppFooter />
		</div>
		<transition name="layout-mask">
			<div class="layout-mask p-component-overlay" v-if="mobileMenuActive" />
		</transition>
	</div>
	<div v-if="!auth.isAuthenticated">
		<Unauthorized />
	</div>
</template>

<style lang="scss">
@import './App.scss';

.p-progressbar-indeterminate .p-progressbar-value {
	background-color: #8bae2c;
}
</style>
