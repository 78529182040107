export const operaciones = () => {
	return {
		extrasItems: {
			config: 'Config. Grillas',
			restore: 'Restaurar Grilla',
		},
		contextMenu: {
			editarPoliza: 'Editar Póliza Original del registro',
			see: 'Ver Bienes y Endosos',
			editarAsegurado: 'Editar Asegurado',
		},
		confirm: {
			message: 'Esta acción elimina los registros anteriores a la fecha seleccionada. ¿Continuar?',
			header: 'Confirmación',
		},
		toast: {
			error: {
				summary: 'Error',
				detail: {
					rubrica: 'Debe seleccionar al menos una rubrica',
					productor: 'Debe seleccionar un productor',
				},
			},
		},
		title: 'Rúbrica - Operaciones',
		sideBarTitle: 'Búsqueda de Rúbricas',
		labels: {
			fechaDesde: 'Fecha de Registro mayor a',
			fechaHasta: 'Fecha de Registro menor a',
			operacion: 'Tipo de Operación',
			numeroPoliza: 'Número de Póliza',
		},
		buttons: {
			generate: 'Generar archivo para SSN',
			delete: 'Eliminar registros antiguos',
		},
		dialog: {
			header: 'Eliminar registros anteriores a la fecha seleccionada',
			label: 'Seleccione la fecha',
		},
		dialogParalelo: {
			header: 'Ejecutando en paralelo',
			label: 'Refrescá el listado en 10 minutos para ver los datos actualizados',
		},
	};
};
