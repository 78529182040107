<script setup>
import { ref, onMounted, computed } from 'vue'
import { importsCias } from '@service/import/ImportsService'
import { useToast } from "primevue/usetoast";

const props = defineProps({
    selectedImport: Object,
})

const emit = defineEmits(['error-report-step-complete','error-report-exit'])

function tieneError(data) {
	return (
		data.amount_operations_failed ||
		data.amount_commissions_failed ||
		data.amount_payments_failed ||
		data.amount_payment_concepts_failed
	);
}

const tiene_error = tieneError(props.selectedImport)

const currentstep = ref(1)
const importNode = ref(null)
const steps = ref([])

const stepperValue = computed(() => {
    return (currentstep.value * 100  / steps.value.length)
})

onMounted(() => {
    importNode.value = importsCias.find((ic) => ic.codigo == props.selectedImport.company.codigo)
    switch (props.selectedImport.error_type){
        case 'connection':
            steps.value.push(
                {
                    content:'<div>'+
                    '<p>El estado de la importación es <b>Error de conexión con la compañia</b>, es un problema temporal en la compañia.</p>'+
                    '<p>Podés volver a ejecutar la importación luego de pasado un dia del error, y si el problema persiste nos contactas a soporte para que demos aviso a la compañía.</p>'+
                    '</div>'
                }
            )
            break;
        case 'auth':
            steps.value.push(
                {
                    content:'<div>'+
                    '<p>El estado de la importación es <b>Error de autenticación</b>, el usuario/contraseña de la interfaz es inválido.</p>'+
                    '<p>Escribinos a soporte@adminse.com.ar reenviándonos el último mail con la habilitación de credenciales que recibiste oportunamente, y comentanos que hay un error de autenticación en la interfaz.</p>'+
                    '</div>'
                }
            )
            break;
        case 'missing_company_credentials':
            steps.value.push(
                {
                    content:'<div>'+
                    '<p>El estado de la importación es <b>Sin credenciales de Interfaz</b>.</p>'+
                    '<p>Si ya hiciste el pedido de alta de interfaz a través del módulo Importaciones y te confirmamos el alta, escribinos a integraciones@adminse.com.ar y comentanos el problema para revisarlo.</p>'+
                    '</div>'
                }
            )
            break;
        default:
            if (props.selectedImport.status == 'error'){
                if (importNode.value.ws_available){
                    // Error por WS sin identificar: que vuelvan a intentar o reporten por mail
                    steps.value.push(
                        {
                            content:'<div>'+
                            '<p>Esta importación tiene un error no identificado en la interfaz.</p>'+
                            '<p>Reejecutá la importación del período en cuestión (si se puede seleccionar).</p>'+
                            '</div>'
                        }
                    )
                } else {
                    // Error por ARCHIVO sin identificar: que lo reporten
                    steps.value.push(
                        {
                            content:'<div>'+
                            '<p>Descargá el archivo de importación que usaste para esta operación, haciendo click derecho en el registro del Historial y seleccionando la opción <b>"Descargar archivo(s) usados en la importación"</b>.'+
                            '<p>Una vez descargado el archivo, abrilo y asegurate que sea el mismo archivo que se pide en el asistente de importación para esta compañía.</p>'+
                            '</div>'
                        }
                    )
                }
            } else {
                steps.value.push(
                    {
                        content:'<div>'+
                        '<p>Antes de enviar el reporte vamos a pedirte que revises cierta información.</p>'+
                        "<p>Tené a mano la póliza/endoso faltante, vas a necesitar los datos: <b>Fecha de emisión, Ramo, Número, Código de Productor que emite la póliza</b>.</p>"+
                        "<p>Podés salir de esta ventana para revisar la información y luego volver a ejecutar esta acción para enviar el reporte de error.</p>"+
                        '</div>'
                    },
                    {
                        content:'<div>'+
                            "<p>Para empezar vamos a revisar lo siguiente:</p>"+
                            "<p>1) Chequeá que la póliza se haya emitido dentro del período de la importación (Desde/Hasta). Caso contrario ejecutá la importación con un período que incluya la fecha de emisión de la póliza.</p>"+
                        '</div>'
                    },
                    {
                        content:'<div>'+                            
                            "<p>2) Buscá la póliza, asegurándote de <b>quitar</b> el filtro de 'Estado' que viene aplicado por omisión, como se muestra en la imágen.</p>"+
                            '<div style="text-align: center;"><img src="/images/import/steps/quitar_estado.png" style="border-radius: 2px; height: 150px;"></div>'+
                        '</div>'
                    }
                )
                // Stepper general para imports por interfaz
                if (!importNode.value.ws_available){
                    steps.value.push(
                        {
                            content:'<div>'+
                                "<p>3) Descarga el archivo de importación que usaste para esta operación, haciendo click derecho en el registro del Historial y seleccionando la opción <b>'Descargar archivo(s) usados en la importación'</b>.</p>"+
                                "<p>Una vez descargado el archivo, abrilo y asegurate que ahi aparezca la póliza que estaría faltando en el sistema.</p>"+
                                '<div style="text-align: center;"><img src="/images/import/steps/download_files.png" style="border-radius: 2px;"></div>'+
                            '</div>'
                        }
                    )
                }
            }

            if (tiene_error){
                // Si tiene errores, agrego el de ver detalle
                steps.value.push(
                    {
                        content:'<div>'+
                            "<p>Finalmente, revisá el detalle de errores de esta importación, haciendo click derecho y seleccionando la opcion <b>'Ver Detalle de Errores'</b>.</p>"+
                            '<p><span style="padding: 5px;background-color: lightgray;color: black;border-radius: 3px;"><i class="la la-warning"></i> Ver Detalle de Errores</span></p>'+
                        '</div>'
                    }
                )
            }

            steps.value.push(
                {
                    enable_send_report: true,
                    content:'<div>'+
                        "<p>Si ya revisaste los pasos anteriores y sigue sin aparecer la póiza en cuestión, podes clicker el boton 'Enviar Reporte' para informarnos el error.</p>"+
                    '</div>'
                }
            )

            break;
    }
})


</script>

<template>
    <div>
        <ProgressBar class="ml-2 mr-2" :value="stepperValue"> {{ currentstep }}/{{ steps.length }} </ProgressBar>
        <Card :pt="{ body: { class: 'border-none', style:'border:none !important;' }, root: { class: 'border-none', style:'box-shadow:none;' } }">
            <template #content>
                <div v-if="steps.length" v-html="steps[currentstep-1].content"></div>
            </template>
            <template #footer>
                <div class="flex">
                    <Button v-if="(currentstep > 1) || steps.length==1" @click="$emit('error-report-exit')" severity="secondary" icon="pi pi-times" label="Salir" />
                    <Button class="ml-2" v-if="steps.length && steps[currentstep-1].enable_send_report" @click="$emit('error-report-step-complete')" severity="success"  icon="pi pi-send" label="Enviar Reporte" />
                    <div class="flex-grow-1"></div>
                    <Button :disabled="currentstep==1" @click="currentstep--" icon="pi pi-angle-double-left" label="Anterior" />
                    <Button class="ml-2" v-if="currentstep!=steps.length" @click="currentstep++" icon="pi pi-angle-double-right" label="Siguiente" />
                </div>
            </template>
        </Card>
    </div>
</template>
<style scoped lang="scss">
</style>